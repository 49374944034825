<script setup lang="ts">
import type { MemberDto } from '~/api/card/models/member-dto'
import MembersRow from '~/components/core/members/MembersRow.vue'
import MemberTopRow from '~/components/core/members/MemberTopRow.vue'

const staticP = `${useRuntimeConfig().public.STATIC}/img/profile`

const memberTop: MemberDto = { id: -5, avatar: `${staticP}/user-5.jpg` }
const title = 'Where is my Boat?'
const subTitle = 'Berlin river 💙 lovers'

const membersTop: MemberDto[] = [
  { id: -1, avatar: `${staticP}/user-2.jpg`, nick: 'Batman' },
  { id: -2, avatar: `${staticP}/user-3.jpg`, nick: 'Walter 123' },
  { id: -3, avatar: `${staticP}/user-4.jpg`, nick: 'VeryLongName & Max chars' },
]
</script>

<template>
  <v-card elevation="10" class="border-thin pa-4">
    <MemberTopRow :member="memberTop" :title="title" :sub-title="subTitle" />

    <MembersRow :members="membersTop" class="mt-4" />
  </v-card>
</template>

<style scoped lang="scss">
.avatar-border {
  border: 2px solid rgb(var(--v-theme-surface)) !important;
}
</style>
